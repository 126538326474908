import { TypeAnimation } from 'react-type-animation';
import React from 'react';
import { WindowContentLayout } from '@layouts/WindowContentLayout/WindowContentLayout';

import './Home.scss';

export const Home = () => {
  return (
    <div className="home">
      <WindowContentLayout>
        <div className="flex home__main">
          <h2>
            Welcome to my personal&nbsp;
            <TypeAnimation
              sequence={[
                'website',
                1000,
                'page',
                1000,
                'application',
                1000,
              ]}
              wrapper="span"
              speed={50}
            />
          </h2>
          <p>Connecting Code, Coffee and Curiosity.</p>
        </div>
      </WindowContentLayout>
    </div>
  );
};
