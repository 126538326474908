import React from 'react';
import { Anchor, Frame } from 'react95';

import './Footer.scss';

export const Footer = () => {
  return (
    <Frame variant="outside" className="footer">
      <p>
        Constructed using: <Anchor href='https://react95.io/' target='_blank'>react95.io</Anchor>
        <br />
        Serhiy Moskovchuk
      </p>
    </Frame>
  );
};