import React from 'react';
import { Anchor, Avatar, GroupBox } from 'react95';
import { WindowContentLayout } from '@layouts/WindowContentLayout/WindowContentLayout';
import { ContentBlock } from '@components/ContentBlock/ContentBlock';
import ImagePhoto from './photo.webp';
import './AboutMe.scss';

export const AboutMe = () => {

  return (
    <div className="about-me">
      <WindowContentLayout>
        <GroupBox className="about-me__content-block" label={<div className="flex">
          <Avatar size={40} src={ImagePhoto} style={{marginRight: '0.5rem'}} />
          <h2 className="about-me__content-block__title">Hello! I'm Serhiy.</h2>
        </div>}>
          <p style={{marginTop: '0.5rem'}}>
            I was born in western Ukraine, but grew up in Portugal and now reside in the vibrant city of Dubai. By
            day I'm a software engineer, by night - a motorcycle junkie. With a deep passion for software engineering,
            I've
            worked and lived in five different countries developing numerous digital platforms, web and hybrid mobile
            applications. My tech stack is primarily Web technologies and React Native. I'm also profoundly
            passionate about cloud computing platforms (AWS) and building CI/CD pipelines
            to enhance performance and efficiency of teams.
          </p>
        </GroupBox>
        <br />
        <ContentBlock title={'A Little More About My Journey'}>
          I graduated from Universidade NOVA de Lisboa with a master's degree in Computer Science and Engineering.
          Upon completing my studies, I decided to move to Italy where I secured an internship and began working for a
          fintech product company. Two years later, I transitioned to consultancy and started travelling abroad as per
          project requirements.
          <br />
          <br />
          Travel became a cornerstone of my life. Discovering new cultures and languages inspired me to
          venture further. As a result, I relocated to the Middle East where I continued in consultancy. However,
          within a year, I shifted gears to join a start-up in the fintech/crypto sector. I love fintech and
          opportunities it opens for people to exchange value.
          <br />
          <br />
          My passion is designing web applications architectures and structuring complex single-page applications
          tailored for scalable teams. Being fluent in five languages, having an ardent passion for
          technology and people-first culture I manged to assemble robust and reliable development teams.
          <br />
          <br />
          Overall with 10 years of experience in digital transformation, products and services
          across the Fintech, Telco, Government and E-Commerce sectors I realised how much I love to be part of
          something
          bigger then my self and contribute to its success.
          As a lead, I provided technical oversight and
          managed engineering teams, handled responsibilities such as hiring, performance reviews, resource
          allocation,
          and planning. Also, I had designed scalable architectures for web applications (incl. micro front-ends) and
          supported my engineers as a fullstack developer.
        </ContentBlock>
        <br />
        <ContentBlock title={'Outside the Digital World'}>
          When I'm not behind a computer screen, you can find me exploring local coffee shops, scuba diving or riding
          my super sport motorcycle on the track or outside the city. I am also a fan of MMO gaming: World of
          Warcraft and Diablo.
        </ContentBlock>
        <br />
        <ContentBlock title={`Let's Connect`}>
          If you want to contact me, please add me on <Anchor href='https://www.linkedin.com/in/moskovchuk'
                                                              target='_blank'>LinkedIn</Anchor> or <Anchor
          href='https://github.com/mkmule' target='_blank'>Github</Anchor>.
        </ContentBlock>
        <br />
        <ContentBlock title={`Serhiy's README.md`}>
          A quick presentation about my professional profile and expectations as Engineering Manager.
          <br />
          <br />
          <iframe
            title={'README Presentation'}
            src="https://docs.google.com/presentation/d/13UKPb_Z71MOh5XJTl7xbb7Ppbi9mLBBODa5NyOh1TMA/embed"
            frameBorder="0" allowFullScreen="true" mozallowfullscreen="true"
            webkitallowfullscreen="true" />
        </ContentBlock>
      </WindowContentLayout>
    </div>
  );
};
