import React from 'react';
import { Route, Routes } from 'react-router-dom';

import './Application.scss';
import { Navigation, NavigationLink } from './components/Navigation/Navigation';
import { Footer } from './components/Footer/Footer';
import { Home } from './pages/Home/Home';
import { AboutMe } from './pages/AboutMe/AboutMe';
import { Resume } from '@pages/Resume/Resume';
import { Skills } from '@pages/Skills/Skills';


function Application() {
  return (
    <div className="app">
      <header className="app__header">
        <Navigation>
          <NavigationLink to="/">Home</NavigationLink>
          <NavigationLink to="/about-me">About me</NavigationLink>
          <NavigationLink to="/skills">Skills</NavigationLink>
          <NavigationLink to="https://github.com/mkmule">Github</NavigationLink>
          <NavigationLink to="/resume">Resume</NavigationLink>
        </Navigation>
      </header>
      <main className="app__main">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-me" element={<AboutMe />} />
          <Route path="/skills" element={<Skills />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="*" element={<p>Sorry, this page does not exist</p>} />
        </Routes>
      </main>
      <footer className="app__footer">
        <Footer />
      </footer>
    </div>
  );
}

export default Application;
