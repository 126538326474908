import React from 'react';
import { Window, WindowContent } from 'react95';

import './WindowContentLayout.scss';

export const WindowContentLayout = ({children}) => {
  return (
    <Window className="window-content-layout">
      <WindowContent className="window-content-layout__main">
        {children}
      </WindowContent>
    </Window>
  )
};