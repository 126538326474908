import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { Button } from 'react95';

import './Resume.scss';
import ResumeFile from './Moskovchuk_CV.pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const getWindowSize = () => {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}
export const Resume = () => {
  const [windowSize] = useState(getWindowSize());

  return (
    <div className="resume">
      <a
        className="resume__download-link"
        href={ResumeFile}
        download="Moskovchuk_CV.pdf"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button fullWidth>
          Download PDF file
        </Button>
      </a>

      <Document file={ResumeFile}>
        <Page width={windowSize.innerWidth} pageNumber={1} />
        <Page width={windowSize.innerWidth} pageNumber={2} />
      </Document>
    </div>
  );
};
