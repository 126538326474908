import { NavLink, useLocation } from 'react-router-dom';
import { MenuList, MenuListItem } from 'react95';

import './Navigation.scss';

export const Navigation = ({children}) => {
  return (
    <nav className="navigation">
      <MenuList inline fullWidth className="navigation__list">
        {children}
      </MenuList>
    </nav>
  );
};

export const NavigationLink = ({children, disabled, to}) => {
  const {pathname} = useLocation()
  const className = pathname === to ? 'navigation__link navigation__link--active' : 'navigation__link';

  return (
    <MenuListItem className={className} disabled={disabled}>
      <NavLink to={to}>{children}</NavLink>
    </MenuListItem>
  );
};
