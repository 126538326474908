import React from 'react';
import { WindowContentLayout } from '@layouts/WindowContentLayout/WindowContentLayout';
import { ContentBlock } from '@components/ContentBlock/ContentBlock';
import { TreeView } from 'react95';

import './Skills.scss';

const engineeringManagementExpanded = [
  'administration',
  'people',
  'tech',
  'prod',
  'tools',
];
const engineeringManagement = [
  {
    id: 'administration',
    label: 'Administration',
    icon: <>💼</>,
    items: [
      {
        id: 'sdlc',
        label: 'Software development lifecycle setup',
        icon: <>●</>,
      },
      {
        id: 'project-management',
        label: 'Project management',
        icon: <>●</>,
      },
      {
        id: 'agile',
        label: 'Agile methodologies (Scrum, Kanban)',
        icon: <>●</>,
      },
    ],
  },
  {
    id: 'people',
    label: 'People management',
    icon: <>🧢</>,
    items: [
      {
        id: 'hiring',
        label: 'Hiring',
        icon: <>●</>,
      },
      {
        id: 'coaching',
        label: 'Coaching and Mentoring',
        icon: <>●</>,
      },
      {
        id: 'conflict',
        label: 'Conflict resolution',
        icon: <>●</>,
      },
      {
        id: 'performance',
        label: 'Performance evaluation',
        icon: <>●</>,
      },
      {
        id: 'growth',
        label: 'Growth framework setup',
        icon: <>●</>,
      },
      {
        id: 'goals',
        label: 'Goals & 1:1s',
        icon: <>●</>,
      },
    ],
  },
  {
    id: 'tech',
    label: 'Technical leadership',
    icon: <>💾</>,
    items: [
      {
        id: 'arch',
        label: 'Solution architecture',
        icon: <>●</>,
      },
      {
        id: 'automation',
        label: 'Process automation (CI/CD)',
        icon: <>●</>,
      },
      {
        id: 'qa',
        label: 'Automation testing (Unit, E2E)',
        icon: <>●</>,
      },
      {
        id: 'legacy',
        label: 'Legacy system migration',
        icon: <>●</>,
      },
      {
        id: 'coding',
        label: 'Fullstack coding support',
        icon: <>●</>,
      },
    ],
  },
  {
    id: 'prod',
    label: 'Production rollout',
    icon: <>🌁</>,
    items: [
      {
        id: 'cab',
        label: 'CAB Presentations',
        icon: <>●</>,
      },
      {
        id: 'plan',
        label: 'Release planning',
        icon: <>●</>,
      },
      {
        id: 'incident',
        label: 'Incident handling',
        icon: <>●</>,
      }
    ],
  },
  {
    id: 'tools',
    label: 'Tools',
    icon: <>🧰</>,
    items: [
      {
        id: 'atlassian',
        label: 'Atlassian suite',
        icon: <>●</>,
      },
      {
        id: 'google',
        label: 'Google Workspace',
        icon: <>●</>,
      },
      {
        id: 'microsoft',
        label: 'Microsoft 365',
        icon: <>●</>,
      }
    ],
  }
];

const webDevelopmentExpanded = [
  'lang-fe',
  'frameworks',
  'build',
  'perf',
  'protocols',
];
const webDevelopment = [
  {
    id: 'lang-fe',
    label: 'Programming languages',
    icon: <>📝</>,
    items: [
      {
        id: 'js',
        label: 'JavaScript / TypeScript',
        icon: <>●</>,
      },
      {
        id: 'css',
        label: 'CSS / SCSS',
        icon: <>●</>,
      },
      {
        id: 'html',
        label: 'HTML5',
        icon: <>●</>,
      },
    ],
  },
  {
    id: 'frameworks',
    label: 'Frameworks and Libraries',
    icon: <>⚙️</>,
    items: [
      {
        id: 'react',
        label: 'React.js',
        icon: <>●</>,
      },
      {
        id: 'next',
        label: 'Next.js',
        icon: <>●</>,
      },
      {
        id: 'angular',
        label: 'Angular 1 & 2+',
        icon: <>●</>,
      },
      {
        id: 'cypress',
        label: 'Cypress.io',
        icon: <>●</>,
      },
      {
        id: 'redux',
        label: 'Redux / Redux-observable',
        icon: <>●</>,
      },
      {
        id: 'd3',
        label: 'D3',
        icon: <>●</>,
      },
      {
        id: 'emotion',
        label: 'Emotion / Styled components',
        icon: <>●</>,
      },
      {
        id: 'material',
        label: 'Material UI',
        icon: <>●</>,
      },
    ],
  },
  {
    id: 'build',
    label: 'Build tools',
    icon: <>📦</>,
    items: [
      {
        id: 'webpack',
        label: 'Webpack',
        icon: <>●</>,
      },
      {
        id: 'npm',
        label: 'NPM / Yarn',
        icon: <>●</>,
      },
    ],
  },
  {
    id: 'perf',
    label: 'Performance / Code quality',
    icon: <>⚡️</>,
    items: [
      {
        id: 'eslint',
        label: 'ESLint',
        icon: <>●</>,
      },
      {
        id: 'prettier',
        label: 'Prettier',
        icon: <>●</>,
      },
      {
        id: 'light',
        label: 'Lighthouse',
        icon: <>●</>,
      },
      {
        id: 'story',
        label: 'Storybook',
        icon: <>●</>,
      },
    ],
  },
  {
    id: 'protocols',
    label: 'Communication protocols',
    icon: <>⚡️</>,
    items: [
      {
        id: 'tcp',
        label: 'TCP / DNS',
        icon: <>●</>,
      },
      {
        id: 'websockets',
        label: 'Websockets',
        icon: <>●</>,
      },
      {
        id: 'mqtt',
        label: 'MQTT',
        icon: <>●</>,
      },
    ],
  },
];

const devOpsExpanded = [
  'cloud',
  'cdn',
  'ci/cd',
  'monitoring',
];
const devOps = [
  {
    id: 'cloud',
    label: 'Cloud platforms',
    icon: <>🌤️</>,
    items: [
      {
        id: 'aws',
        label: 'AWS',
        icon: <>●</>,
      },
      {
        id: 'firebase',
        label: 'Firebase',
        icon: <>●</>,
      },
    ],
  },
  {
    id: 'cdn',
    label: 'CDN',
    icon: <>💨</>,
    items: [
      {
        id: 'cloudflare',
        label: 'Cloudflare',
        icon: <>●</>,
      },
      {
        id: 'cloudfront',
        label: 'CloudFront',
        icon: <>●</>,
      },
      {
        id: 'lambda',
        label: 'Lambda Edge',
        icon: <>●</>,
      },
      {
        id: 'route53',
        label: 'Route 53',
        icon: <>●</>,
      },
    ],
  },
  {
    id: 'ci/cd',
    label: 'CI / CD',
    icon: <>🔀</>,
    items: [
      {
        id: 'github',
        label: 'Github actions',
        icon: <>●</>,
      },
      {
        id: 'argo',
        label: 'ArgoCD',
        icon: <>●</>,
      },
    ],
  },
  {
    id: 'monitoring',
    label: 'Monitoring',
    icon: <>👀</>,
    items: [
      {
        id: 'cloudwatch',
        label: 'CloudWatch',
        icon: <>●</>,
      },
      {
        id: 'sentry',
        label: 'Sentry',
        icon: <>●</>,
      },
      {
        id: 'honey',
        label: 'Honeycomb',
        icon: <>●</>,
      },
    ],
  },
];

const backendExpanded = [
  'lang-be',
  'frameworks-be',
  'dbs',
];
const backend = [
  {
    id: 'lang-be',
    label: 'Programming languages',
    icon: <>📝</>,
    items: [
      {
        id: 'js-ts',
        label: 'JavaScript / TypeScript',
        icon: <>●</>,
      },
      {
        id: 'java',
        label: 'Java',
        icon: <>●</>,
      },
      {
        id: 'go',
        label: 'Go lang',
        icon: <>●</>,
      },
      {
        id: 'cpp',
        label: 'C++',
        icon: <>●</>,
      },
    ],
  },
  {
    id: 'frameworks-be',
    label: 'Frameworks and Libraries',
    icon: <>⚙️</>,
    items: [
      {
        id: 'node',
        label: 'Node.js',
        icon: <>●</>,
      },
      {
        id: 'express',
        label: 'Express',
        icon: <>●</>,
      },
      {
        id: 'prisma',
        label: 'Prisma (Database ORM)',
        icon: <>●</>,
      },
      {
        id: 'spring',
        label: 'Spring',
        icon: <>●</>,
      },
      {
        id: 'opencv',
        label: 'OpenCV',
        icon: <>●</>,
      },
    ],
  },

  {
    id: 'dbs',
    label: 'Databases',
    icon: <>🥞</>,
    items: [
      {
        id: 'postgres-mysql',
        label: 'PostgreSQL / MySQL',
        icon: <>●</>,
      },
      {
        id: 'firestore',
        label: 'Firestore',
        icon: <>●</>,
      },
      {
        id: 'dynamo',
        label: 'DynamoDB',
        icon: <>●</>,
      },
    ],
  },
];

const mobileExpanded = [
  'mobile',
];
const mobile = [
  {
    id: 'mobile',
    label: 'Hybrid mobile',
    icon: <>📱</>,
    items: [
      {
        id: 'react-native',
        label: 'React native',
        icon: <>●</>,
      },
      {
        id: 'cordova',
        label: 'Cordova',
        icon: <>●</>,
      },
    ],
  }
];

const personalExpanded = [
  'lang',
];
const personal = [
  {
    id: 'lang',
    label: 'Spoken languages (fluent)',
    icon: <>📣</>,
    items: [
      {
        id: 'en',
        label: 'English',
        icon: <>●</>,
      },
      {
        id: 'pt',
        label: 'Portuguese',
        icon: <>●</>,
      },
      {
        id: 'it',
        label: 'Italian',
        icon: <>●</>,
      },
      {
        id: 'ua',
        label: 'Ukrainian',
        icon: <>●</>,
      },
      {
        id: 'ru',
        label: 'Russian',
        icon: <>●</>,
      },
    ],
  }
];


const coursesExpanded = [
  'certifications',
  'tech-courses',
];
const listCertifications = [
  {
    href: 'https://www.credly.com/badges/47df9758-c2e1-424c-9e01-2e81f1765a74/linked_in_profile',
    label: 'Google Project Management Certificate (Oct, 2023)',
  },
  {
    href: 'https://www.coursera.org/account/accomplishments/specialization/certificate/9UN4BCKYUSQB',
    label: 'AWS Cloud Solutions Architect Specialization (Nov, 2023)',
  },
  {
    href: 'https://www.udemy.com/certificate/UC-b9fbdec4-4a1b-4a0b-821f-7484ef5bdf61/',
    label: 'Management Skills Training for New & Experienced Managers (Jan, 2024)',
  }
];
const listCourses = [
  {
    href: 'https://frontendmasters.com/courses/fullstack-app-next-v3/',
    label: 'Build an AI-Powered Fullstack Next.js App, v3',
  },
  {
    href: 'https://frontendmasters.com/courses/api-design-nodejs-v4',
    label: 'API Design in Node.js, v4',
  },
  {
    href: 'https://frontendmasters.com/courses/next-js-v3/',
    label: 'Introduction to Next.js 13+, v3',
  },
  {
    href: 'https://frontendmasters.com/courses/aws-v2/',
    label: 'AWS For Front-End Engineers, v2',
  },
  {
    href: 'https://frontendmasters.com/courses/cypress/',
    label: 'Testing Web Apps with Cypress',
  },
  {
    href: 'https://frontendmasters.com/courses/d3/',
    label: 'Introduction to D3.js',
  },
  {
    href: 'https://frontendmasters.com/courses/complete-intro-containers/',
    label: 'Complete Intro to Containers (feat. Docker)',
  },
  {
    href: 'https://frontendmasters.com/courses/archive/go-for-js-devs/',
    label: 'Go for JavaScript Developers',
  },
  {
    href: 'https://frontendmasters.com/courses/go-basics/',
    label: 'Basics of Go',
  },
  {
    href: 'https://frontendmasters.com/courses/tailwind-css/',
    label: 'Tailwind CSS',
  },
  {
    href: 'https://frontendmasters.com/courses/intro-management/',
    label: 'Engineering Management Fundamentals 101',
  },
  {
    href: 'https://frontendmasters.com/courses/engineering-management/',
    label: 'Enterprise Engineering Management 102',
  },
];
const courses = [
  {
    id: 'certifications',
    label: 'Certifications',
    icon: <>📖</>,
    items: listCertifications.map(elem => ({id: elem.href, icon: <>●</>, label: elem.label})),
  },
  {
    id: 'tech-courses',
    label: 'Tech/Coding Courses',
    icon: <>👨‍💻</>,
    items: listCourses.map(elem => ({id: elem.href, icon: <>●</>, label: elem.label})),
  }
];

export const Skills = () => {
  const onNodeToggle = () => {
    // to ignore
  }

  const onNodeSelect = (event, id) => {
    if ((id || '').startsWith('https://')) {
      window.location.assign(id);
      return;
    }

    console.warn('Invalid URL', id);
  }

  return (
    <div className="skills">
      <WindowContentLayout>
        <div className="row">
          <div className="col-xs-12">
            <ContentBlock title={'Summary'}>
              <p>
                It has been almost 10 years that I work in Information Technology sector. During my career I lead
                multifunctional teams, performed complex system integrations, delivered hybrid mobile applications and
                fintech
                web platforms. Experience of working in consultancy added solid project
                management, planing, and prioritisation skills to my profile.

                In order to stay up-to-date with latest technologies and trends I constantly keep learning by doing
                hands-on courses and reading relevant online resources. This helps me design solid systems
                architectures, evaluate tech. stacks, benchmark system performance and grow engineers.
              </p>
            </ContentBlock>
            <br />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <ContentBlock title={'People First'}>
              <p>
                I believe in the power of <b>working together as a ONE team</b>, where everyone shares their
                views
                within sight of
                a
                shared objective.
                Cooperation, discussion, disagreement, brainstorming, are all necessary in order to converge in the best
                solution. <b>Discuss ideas not people</b>, I value Openness and Honesty.
                <br />
                People are my
                priority, independently if I am
                operating as an engineering manager, tech lead or individual contributor, empowering and motivating
                people makes team stronger and produce great outcomes.
              </p>
            </ContentBlock>
            <br />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-xs-12">
            <ContentBlock title={'Engineering Management'}>
              <TreeView className="skills__content-pair__tree" tree={engineeringManagement}
                        expanded={engineeringManagementExpanded} onNodeToggle={onNodeToggle} />
            </ContentBlock>
            <br />
          </div>
          <div className="col-md-6 col-xs-12">
            <ContentBlock title={'Web Development'}>
              <TreeView tree={webDevelopment} expanded={webDevelopmentExpanded} onNodeToggle={onNodeToggle} />
            </ContentBlock>
            <br />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-xs-12">
            <ContentBlock title={'DevOps'}>
              <TreeView tree={devOps} expanded={devOpsExpanded} onNodeToggle={onNodeToggle} />
            </ContentBlock>
            <br />
          </div>
          <div className="col-md-6 col-xs-12">
            <ContentBlock title={'Backend Development'}>
              <TreeView tree={backend} expanded={backendExpanded} onNodeToggle={onNodeToggle} />
            </ContentBlock>
            <br />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-xs-12">
            <ContentBlock title={'Mobile Applications'}>
              <TreeView tree={mobile} expanded={mobileExpanded} onNodeToggle={onNodeToggle} />
            </ContentBlock>
            <br />
          </div>
          <div className="col-md-6 col-xs-12">
            <ContentBlock title={'Soft Skills'}>
              <TreeView tree={personal} expanded={personalExpanded} onNodeToggle={onNodeToggle} />
            </ContentBlock>
            <br />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-xs-12 special-links">
            <ContentBlock title={'Courses & Certifications'}>
              I want to keep my technical skills sharp, so I do allocate time for learning. It can be improvement of my
              existing skills, knowledge refresh or even learning something completely new from scratch. Frontend
              Masters is one of my favorite places to do so as it offers high quality hands-on courses lectured by
              outstanding industry-leading engineers.
              <TreeView tree={courses} expanded={coursesExpanded} onNodeToggle={onNodeToggle}
                        onNodeSelect={onNodeSelect} />
            </ContentBlock>
            <br />
          </div>
        </div>
      </WindowContentLayout>
    </div>
  );
};
